@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'material.scss';
@import 'variables.scss';

html {
  height: 100%;
  top : 0px !important;
}

body {
  height: 100%;
  overflow: auto;
  display: block !important;
}

c-header {
  flex: none !important;
}

c-content {
  flex: 1 !important;
  padding: 2rem;
}

.no-gutter {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-gutter>[class*='col-'] {
  padding-right: 2rem !important;
  padding-left: 0 !important;
  padding-top: 3px !important;
}

.default-pointer {
  cursor: default;
}

.fh {
  height: 100%;
}

.fh-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
}

.fh-panel-child {
  height: 0;
  padding: 0 !important;
  margin-top: 1px !important;
}

.col-left {
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-right {
  padding-left: 1rem;
  padding-right: 1rem;
}

.overflow-auto {
  overflow: auto;
}

.center-h {
  margin: 0 auto;
}

.panel-info,
.card-footer {
  font-size: 3rem;
  border-top: solid 1px #dadada;

  .section {
    padding: 0.8em 1em;
  }

  .left {
    border-right: solid 1px #dadada;
  }

  .right {
    border-left: solid 1px #dadada;
    clear: right;
  }
}

.card-header {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
  }
}

.loader {
  z-index: 1000;
  background-color: rgba(244, 244, 244, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    text-align: center;

    i {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 12rem;
    }
  }

  .spinner {
    margin: 0 auto;
    border: 2rem solid $color-primary-01;
    border-top: 2rem solid $interaction-primary;
    border-radius: 50%;
    width: 15rem;
    height: 15rem;
    animation: spin 0.5s linear infinite;

    .success {
      border-top: 1rem solid $interaction-success;
    }

    .info {
      border-top: 1rem solid $interaction-info;
    }
  }

  .dots {
    text-align: center;
  }

  .dots>div {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.3rem;
    border-radius: 100%;
    display: inline-block;
    background-color: $color-primary-01;

    .success {
      background-color: 2rem solid $interaction-success;
    }

    .info {
      background-color: 2rem solid $interaction-info;
    }

    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .dots .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .dots .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .message {
    clear: both;
    font-size: 5rem;
    font-family: 'Scania Sans Headline';
    font-weight: bold;
    margin-top: 1.3rem;
    color: $color-primary-03;
  }

  .error {
    clear: both;
    font-size: 3rem;
    font-family: 'Scania Sans';
    font-weight: bold;
    margin-top: 1.3rem;
    color: $interaction-danger;
  }

  .distinct {
    color: $interaction-primary !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.table-header {
  margin-bottom: 0 !important;
  font-size: 2.5rem;
  background-color: #041e42;
  color: white;
}

.table {
  margin-top: 0.5rem !important;
  border: none;

  .mw-20 {
    min-width: 2rem;
  }

  .mw-30 {
    min-width: 3rem;
  }

  .mw-40 {
    min-width: 4rem;
  }

  .mw-50 {
    min-width: 5rem;
  }

  .mw-75 {
    min-width: 7.5rem;
  }

  .mw-80 {
    min-width: 8rem;
  }

  .mw-100 {
    min-width: 10rem;
  }

  .mw-150 {
    min-width: 15rem;
  }

  .mw-200 {
    min-width: 20rem;
  }

  .w-20 {
    min-width: 2rem !important;
    width: 2rem !important;
    max-width: 2rem !important;
  }

  .w-30 {
    min-width: 3rem !important;
    width: 3rem !important;
    max-width: 3rem !important;
  }

  .w-40 {
    min-width: 4rem !important;
    width: 4rem !important;
    max-width: 4rem !important;
  }

  .w-50 {
    min-width: 5rem !important;
    width: 5rem !important;
    max-width: 5rem !important;
  }

  .w-75 {
    min-width: 7.5rem !important;
    width: 7.5rem !important;
    max-width: 7.5rem !important;
  }

  .w-100 {
    min-width: 10rem !important;
    width: 10rem !important;
    max-width: 10rem !important;
  }

  .w-125 {
    min-width: 12.5rem !important;
    width: 12.5rem !important;
    max-width: 12.5rem !important;
  }

  .w-140 {
    min-width: 14rem !important;
    width: 14rem !important;
    height: 5rem !important;
    max-width: 14rem !important;
  }

  .w-150 {
    min-width: 15rem !important;
    width: 15rem !important;
    max-width: 15rem !important;
  }

  .w-200 {
    min-width: 20rem !important;
    width: 20rem !important;
    max-width: 20rem !important;
  }

  .w-250 {
    min-width: 25rem !important;
    width: 25rem !important;
    max-width: 25rem !important;
  }

  .w-300 {
    min-width: 30rem !important;
    width: 30rem !important;
    max-width: 30rem !important;
  }

  .w-350 {
    min-width: 35rem !important;
    width: 35rem !important;
    max-width: 35rem !important;
  }
  .w-400 {
    min-width: 40rem !important;
    width: 40rem !important;
    max-width: 40rem !important;
  }

  thead,
  tbody {
    display: contents;
  }

  tr {
    cursor: pointer;

    &.row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  td,
  th {
    position: static !important;
    display: table-cell !important;
    float: none !important;
    font-size: 3rem !important;
    padding: 1rem !important;
    vertical-align: middle;
  }

  th {
    background-position-x: 93%;
    background-position-y: 65%;
    background-repeat: no-repeat;
  }

  th.separated {
    border-right: solid 0.1rem $color-grey5 !important;
  }

  td.separated {
    border-right: solid 0.1rem $color-grey4 !important;
    border-bottom: solid 0.1rem $color-grey4 !important;
  }

  th.sorting {
    border-right: solid 0.1rem $color-grey5;

    &.none {
      background-image: url(/assets/images/sort-none.png);
    }

    &.asc {
      background-image: url(/assets/images/sort-asc.png);
    }

    &.desc {
      background-image: url(/assets/images/sort-desc.png);
    }

    &:hover,
    &:active {
      background-color: var(--dark-03);
    }
  }
}

.checkbox-list {
  flex: 1;
  margin-bottom: 0.5rem;

  .col-md-6 {
    padding-left: 0;
    padding-right: 0;

    div {
      width: 100%;
      cursor: pointer;
    }
  }

  .checkbox {
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.gly-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.modal-backdrop {
  opacity: 0;
}

.modal.fade {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  vertical-align: middle;
}

.pane-hScroll {
  height: 100% !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.header-icon {
  font-size: 2rem;
  padding-right: 7px;
  padding-left: 7px;

  &.far,
  &.fas {
    font-size: 2.3rem;
  }
}

.headerbuttonsalign {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;

  &[disabled] {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26)
  }
}

.action-icon {
  cursor: pointer;
  color: darkslategrey;

  &:hover {
    color: darkorange;
  }

  &.disabled {
    cursor: default;
    color: #999;

    &:hover {
      color: #999;
    }
  }
}

.linkSeparator {
  width: 2px;
  border-left: solid 1px rgb(223, 223, 223);
  border-right: solid 1px rgb(195, 195, 195);
  height: 110%;
}

.fullscreen-modal {
  min-width: 100.5% !important;
  margin-left: auto;
  margin-right: auto;

  .modal-content {
    height: 100.5vh;
    min-height: 80rem;
    display: flex;
    flex-direction: column;

    .modal-header {
      min-height: 5rem;
    }

    .modal-body {
      flex: 1;
      padding: 10px;
      overflow: auto;

      @media only screen and (max-width: 991px) {
        padding: 1.5rem 2rem 2rem 2rem;
      }
    }

    @media only screen and (max-width: 991px) {
      border: 0;
      margin-top: 6.4rem;
      height: auto;
    }
  }

  @media only screen and (max-width: 991px) {
    width: 100% !important;
  }
}

.modal-dialog {
  width: 100% !important;
}

mat-label.disabled {
  pointer-events: none;
  color: #999 !important;
}

div.errmessage {
  font-size: 3rem;
  font-family: 'Scania Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: $interaction-danger;
  text-align: center;
  vertical-align: middle;
  margin: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

[hidden] {
  display: none !important;
}

.form-field-section-label {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -5px;
}

.ng-select.custom .ng-select-container {
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
  min-height: 30px;
  align-items: center;
}

.ng-select.ng-select-single.custom .ng-select-container {
  height: 30px;
}

.ng-select.custom .ng-arrow-wrapper .ng-arrow {
  border-color: #000000 transparent transparent;
  border-width: 5px 3px 2.5px;
}

.ng-select.custom .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #000000;
}

.ng-select.custom .ng-arrow-wrapper {
  width: 15px;
  padding-right: 4px;
  margin-left: -5px;
}

.dropdown-menu {
  font-size: 2.2rem !important;
  font-family: 'Scania Sans Semi Condensed' !important;
}

li {
  a {
    cursor: pointer;

    &:hover {
      background-color: #d9e8ef !important;
    }

    &.selected {
      color: #fff !important;
      background-color: $interaction-primary !important;
    }
  }
}

.navbar-default .navbar-header .navbar-nav {

  a:hover,
  a:focus {
    background: transparent !important;
    text-decoration: none !important;
  }
}

#TotalPicture, #HoleInformationPrint {
  visibility: hidden;
  position: absolute;
}

@media print {
  @page {
    padding: 0 !important;
    size: auto !important;
  }

  html,
  body {
    line-height: 0cm !important;
  }

  * {
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
  }

  #TotalPicture {
    * {
      visibility: visible !important;
      position: relative !important;
      page-break-before: avoid !important;
      page-break-after: always !important;
    }
  }

  #HoleInformationPrint {
    * {
      visibility: visible !important;
      line-height: initial !important;
    }
    width: 100%;
    .table {
      margin: 0 auto !important;
      width: 0;
      thead { 
        display: table-header-group !important; 
        color: black;
        background-color: white;
      }
      tbody { display: table-row-group !important; }
      tfoot { display: table-footer-group !important; }
      &.table-top-spacing { 
        margin-top: 1cm !important; 
      }
      th, td {
        padding: 0.1cm 0.3cm !important;
        font-size: 1rem;
        white-space: nowrap;
      }
      @media (orientation: portrait) {
        th.rotate {
          height: 60px;
          white-space: nowrap;
        }
        th.rotate > div {
          transform: translate(-5px, -10px) rotate(315deg);
          width: 45px;
        }
      }
    }
  }
}

.auto-scroll {
  overflow: auto;
}

.h-0 {
  height: 0;
}

.modal-backdrop.in {
  opacity: 0.7 !important;
}

.mat-icon-flip-horizontal {
  transform: scale(-1, 1);
}

.card-disabled {
  .card {
    pointer-events: none;
    opacity: 0.6;
    background-color: var(--light-01);
    color: rgba(0, 0, 0, 0.26);
  }
}

.card-header {

  .mat-button,
  .mat-icon-button {
    height: 3rem;
    width: 4rem;
    line-height: 4rem;
    margin-right: 4rem;
  }
}
.expand-mr{
  margin-right: 0rem !important;
}

.mat-chip {
  .mat-icon {
    transform: scale(0.75);
  }
}

.dropdown-menu {
  width: 100%;

  &.selectable {
    padding: 0;
  }

  .dropdown-item {
    padding: .8rem 1rem;
    font-size: 3.5rem;

    &.selected {
      background-color:#033a6a !important;
      color: #033a6a !important;
    }
  }
}

.ngx-contextmenu .dropdown-menu {
  padding: 0;

  li {
    a {
      display: block;
      padding: 0.4rem 0.6rem;
      text-decoration: none;
    }

    a:hover {
      background-color: #033a6a !important;
      color: var(--dark);
    }
  }
}

.btn-group+.btn-group,
.btn-group+.btn {
  margin-left: 1rem;
}

.mat-slider-multiple-labels {
  mat-label {
    padding: 0 0.5rem;
    font-weight: bold;
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    color: #C8C9C7;

    &.selected {
      color:  #033a6a !important;
    }
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color:  #94999d;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fff;
  }
}

.success-snackbar {
  color: white !important;
  background-color: #51A351 !important;
}

.success-snackbar button {
  background-color: #51A351 !important;
  color: white !important;
}

.warning-snackbar {
  color: white !important;
  background-color: #F89406 !important;
}

.warning-snackbar button {
  background-color: #F89406 !important;
  color: white !important;
}

.error-snackbar {
  color: white !important;
  background-color: #cc1040 !important;
}

.error-snackbar button {
  background-color: #cc1040;
  color: white !important;
}

.info-snackbar {
  color: white !important;
  background-color: #2F96B4 !important;
}

.info-snackbar button {
  background-color: #2F96B4;
  color: white !important;
}

.pane-hHole {
  overflow: hidden !important;
}

.pane-vHole {
  overflow-y: hidden !important;
  overflow-x: hidden;
  flex: 1;
}

.p-datatable .p-datatable-tbody>tr:hover {
  background-color: #dbebf8 !important;
  outline-color: #dbebf8 !important;
}

.p-highlight {
  background-color: transparent !important;
  outline-color: transparent !important;
}

.p-component {
  font-size: 3.5rem !important;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed" !important;
  color: var(--dark) !important;
}

.ui-datatable-scrollable-body {
  overflow-anchor: none;
}

.selected-row {
  background-color: #a6d5fa !important;
  outline-color: #a6d5fa !important;
}

.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-thead>tr>th {
  padding: 0.6rem 0.6rem !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: #041e42 !important;
  color: #fff !important;
}

.w-100-px {
  width: 100px;
}

.database-title {
  display: inline-block;
  margin-right: 1rem;
}

.margin-t-2 {
  margin-top: .2rem !important;
}

.search-definition-select-panel.mat-select-panel,
.multi-select-panel.mat-select-panel {
  margin-top: 2.25rem;
  margin-left: 3.75rem;
  min-width: 100% !important;
}
.btn-group-sm>.btn, .btn-sm{
  padding: 0.25rem 0.5rem;
  font-size: small;
  line-height: 1.5;
  border-radius: 0.2rem
}
.form-control{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 3.5rem !important;
  line-height: 2.5 !important;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-size-white{
  background-color: #fff !important;
  width: 27rem !important;
  padding: 0.5rem 1.6rem 0.5rem !important;
  font-size: 3.5rem !important;
  border-color:var(--sdds-blue-800) !important;
  color:var(--sdds-blue-800)!important;
  font-weight: bold !important;
}
.btn-size-white:hover{
  background-color: #dee2e6 !important;
  color: #212529 !important;
}

.btn-sz{
  background-color: #102c53 !important;
  width: 27rem !important;
  padding: 0.5rem 1.6rem 0.5rem !important;
  font-size: 3.5rem !important;
  border-color:var(--sdds-blue-800)!important;
  color:#fff !important;
}
btn-size:hover{
  background-color: #fff !important;
  color: #375074 !important;

}
.btn-mrg{
  color:var(--sdds-blue-800) !important;
  margin:  0rem 0rem 0rem 0rem !important;
}
.text-align-right{
  text-align: right
}
.card-header-fh{
  padding: 7px !important;
}
.card-header-info{
  padding: 11px !important;
}
.pi {
  font-size: 2rem !important;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  display: none !important
}