@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/theming';
@import 'variables.scss';
@import 'material-icons/iconfont/material-icons.css';

@include mat.core();

.mat-form-field {
  &.mat-focused {
    .mat-form-field-label {
      color: $color-brand-01;
    }
    .mat-form-field-ripple {
      background-color: $color-brand-01;
    }
  }
}

.mat-form-field-infix {
  margin-top: 0.5rem;
}

.mat-chip-remove {
  align-items: center;
  justify-content: center;
  display: flex;
}
