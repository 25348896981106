// PORTAL NAVIGATION
$tab-border: #ddd;
$tab-bg-active: #f0f0f0;
$tab-bg-inactive: #fff;

// MISC
$main-font-color: #53565a; //Main font color
$inverted-font-color: #fafafa; //Inverted font color
$text-label-color: #949494; //Table-headings, form-labels
$text-muted-color: #949494;
$text-pale-color: #767676;
$text-pale-strong-color: #949393;

// Public
$font-size: 1.8rem;
$h1-font-size: 9rem;
$h2-font-size: 6rem;
$h3-font-size: 3.5rem;
$h4-font-size: 2.4rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.8rem;
$td-font-size: 1.8rem;

// Application
$font-size-app: 1.4rem;
$h1-font-size-app: 2.2rem;
$h2-font-size-app: 2rem;
$h3-font-size-app: 1.8rem;
$h4-font-size-app: 1.6rem;
$h5-font-size-app: 1.4rem;
$h6-font-size-app: 1.4rem;
$td-font-size-app: 1.4rem;

// Article
$h1-font-size-article: 4.5rem;
$h2-font-size-article: 3rem;
$h3-font-size-article: 2.6rem;
$h4-font-size-article: 2.3rem;
$h5-font-size-article: 2rem;
$h6-font-size-article: 1.8rem;
$p-font-size-article: 1.8rem;
$td-font-size-article: 1rem;

//Alert
$alert-font-size-app: 1.2rem;

//Tooltip
$tooltip-font-size-app: 1.2rem;

// MAIN BRAND COLORS
$color-brand-01: #041e42;
$color-brand-02: #fafafa;
$color-brand-03: #d6001c;

// PRIMARY PALETTE
$color-primary-01: #c8c9c7;
$color-primary-02: #97999b;
$color-primary-03: #53565a;
$color-primary-04: #2d2926;

// SECONDARY PALETTE
$color-secondary-01: #e35205;
$color-secondary-02: #ceb888;
$color-secondary-03: #94a596;
$color-secondary-04: #2c5234;

// TERTIARY PALETTE
$color-tertiary-01: #448691;
$color-tertiary-02: #5e3885;
$color-tertiary-03: #005d6d;
$color-tertiary-04: #e1a93e;
$color-tertiary-05: #896a73;
$color-tertiary-06: #5da7a9;
$color-tertiary-07: #60b269;
$color-tertiary-08: #ff7b7d;

// INTERACTION COLORS
$interaction-primary: #041e42;
$interaction-primary-app: #033a6a;
$interaction-success: #44a878;
$interaction-warning: #f2855f;
$interaction-danger: #cc1040;
$interaction-info: #4a8ba4;
$interaction-default: #fafafa;

// GREYSCALE
$color-grey1: #fafafa;
$color-grey2: #f6f9fa;
$color-grey3: #f0f0f0;
$color-grey4: #e7e8ea;
$color-grey5: #e2e2e2;
$color-grey6: #c8c9c7;
$color-grey7: #97999b;
$color-grey8: #53565a;
$color-grey9: #2d2926;

// GRADIENTS
$gradient-01-color-a: #60e266;
$gradient-01-color-b: #b9ee51;
$gradient-01-color-c: #ffec00;
$gradient-01-color-d: #eda053;
$gradient-01-color-e: #e16060;

// BUTTONS
$btn-default-color: $interaction-primary-app;
$btn-default-bg: #fff;
$btn-default-border: $interaction-primary-app;

$btn-primary-color: #fff;
$btn-primary-bg: $interaction-primary;
$btn-primary-border: $interaction-primary;

$btn-success-color: #fff;
$btn-success-bg: $interaction-success;
$btn-success-border: $interaction-success;

$btn-warning-color: #fff;
$btn-warning-bg: $interaction-warning;
$btn-warning-border: $interaction-warning;

$btn-danger-color: #fff;
$btn-danger-bg: $interaction-danger;
$btn-danger-border: $interaction-danger;

$btn-info-color: #fff;
$btn-info-bg: $interaction-info;
$btn-info-border: $interaction-info;

$btn-link-color: $interaction-primary-app;

$btn-link-disabled-color: #999;

$btn-font-size-lg-app: 1.6rem;
$btn-font-size-app: 1.4rem;
$btn-font-size-sm-app: 1.2rem;
$btn-font-size-xs-app: 1.1rem;

// OTHER
$disabledOpacity: 50;

// GLOBAL HEADER
$agora-color: #c1d72d;
$gh_input-field-bg: #333;
$gh_input-field-color: #ccc;
$gh_buttons-bg: #444;

$main-background: $color-grey5; //Page background-color
$dark-content-block: lighten($main-background, 2%);

// TYPOGRAPHY
$headline-color-level1: $color-brand-01;
$headline-color-level2: #ccc;
